import React, { Component, Suspense } from 'react';
import { applyRouterMiddleware, browserHistory, Route, IndexRoute, Router, Redirect } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { isSuperadmin } from 'util/auth';
import { store } from 'redux/store.js';
import Modal from 'components/modal/Modal';
import ScholaMatchProvider from './pages/scholamatch/context/ScholaMatchContext';
import { ScholaMatchResults } from './pages/schola-match-results/schola-match-results';
import pixels from 'util/pixels';

const AboutUs = React.lazy(() => import('components/pages/about-us/about-us'));
const LandingPage = React.lazy(() => import('components/pages/landing-page/landing-page'));
const SchoolProfile = React.lazy(() => import('./pages/school-profile/school-profile'));
const LandingPagev2 = React.lazy(() => import('components/pages/landing-page-new/LandingPage'));
const MainLayout = React.lazy(() => import('components/layouts/MainLayout'));
const MainLayoutMarketing = React.lazy(() => import('components/layouts/MainLayoutMarketing'));
const SchoolProfileLayout = React.lazy(() => import('components/layouts/SchoolProfileLayout'));
const LoginPage = React.lazy(() => import('components/pages/login-page/LoginPage'));
const SignupPage = React.lazy(() => import('components/pages/login-page/SignupPage'));
const LogoutPage = React.lazy(() => import('components/pages/LogoutPage'));
const ContactPage = React.lazy(() => import('components/pages/ContactPage'));
const RedirectPage = React.lazy(() => import('components/pages/RedirectPage'));
const SchoolApplicationPage = React.lazy(() =>
  import('components/pages/school-application-page/SchoolApplicationPage')
);
const SchoolsResults = React.lazy(() => import('components/pages/schools-page/beta/SchoolsPagev3'));
const WhitelabelLayout = React.lazy(() => import('components/layouts/whitelabel/WhitelabelLayout'));
const ScholaMatchV3 = React.lazy(() => import('components/pages/schola-match/ScholaMatch'));
const ScholaMatchSpot = React.lazy(() => import('components/pages/scholamatch/scholamatch'));
const ScholaMatchResultSpot = React.lazy(() => import('components/pages/scholamatch-result-spot/ScholaMatch'));
const ScholaMatchV4 = React.lazy(() => import('components/pages/scholamatch/scholamatch'));
const ScholaMatchV5 = React.lazy(() => import('components/pages/scholamatch/schola-match-v5'));
const scholaMatchFillout = React.lazy(() => import('components/pages/schola-match-fillout/schola-match-fillout'));
const scholaMatchFilloutv6 = React.lazy(() => import('components/pages/schola-match-fillout/schola-match-fillout-v6'));
const PersonalityOverview = React.lazy(() =>
  import('./pages/schools-results-v2/personality-overview/personality-overview')
);
const PersonalityMatch = React.lazy(() => import('components/pages/personality-match/personality-match'));
const ScholaResultsv2 = React.lazy(() => import('./pages/schools-results-v2/schools-results'));
const ScholaMatchLayout = React.lazy(() =>
  import('./pages/scholamatch/components/ScholaMatchLayout/ScholaMatchLayout')
);
const InfoSection = React.lazy(() => import('components/pages/scholamatch/containers/InfoSection/InfoSection'));
const SchoolType = React.lazy(() => import('components/pages/scholamatch/containers/SchoolType'));
const LearningEnvironment = React.lazy(() => import('./pages/scholamatch/containers/LearningEnvironment'));
const SpecificPrograms = React.lazy(() => import('./pages/scholamatch/containers/SpecificPrograms'));
const SupportServices = React.lazy(() => import('./pages/scholamatch/containers/SupportServices'));
const WantSpecialities = React.lazy(() => import('./pages/scholamatch/containers/Interest/WantSpecialities'));
const ChooseSpecialities = React.lazy(() => import('./pages/scholamatch/containers/Interest/ChooseSpecialities'));
const NeedEnglishLearningSupport = React.lazy(() =>
  import('./pages/scholamatch/containers/NeedEnglishLearningSupport')
);
const ChildNeedSpecialEducationServices = React.lazy(() =>
  import('./pages/scholamatch/containers/ChildNeedSpecialEducationServices')
);
const ChildHaveSpecialEducationNeeds = React.lazy(() =>
  import('./pages/scholamatch/containers/ChildHaveSpecialEducationNeeds')
);
const SpecificSchool = React.lazy(() => import('./pages/scholamatch/containers/SpecificSchool'));
const SpecificSchoolSelect = React.lazy(() => import('./pages/scholamatch/containers/SpecificSchoolSelect'));
const SelectCharacteristicIdealSchool = React.lazy(() =>
  import('./pages/scholamatch/containers/SelectCharacteristicIdealSchool')
);
const WhichCharacteristicBeNiceSchoolHave = React.lazy(() =>
  import('./pages/scholamatch/containers/WhichCharacteristicBeNiceSchoolHave')
);
const SingleSexSchool = React.lazy(() => import('./pages/scholamatch/containers/SingleSexSchool'));
const WithReligious = React.lazy(() => import('./pages/scholamatch/containers/WithReligious'));
const ReligiousAffiliation = React.lazy(() => import('./pages/scholamatch/containers/ReligiousAffiliation'));
const HowDoYouFeelExtracurricular = React.lazy(() =>
  import('./pages/scholamatch/containers/Interest/HowDoYouFeelExtracurricular')
);
const SelectActivities = React.lazy(() => import('./pages/scholamatch/containers/Interest/SelectActivities'));
const InterestSports = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestSports'));
const ExploreSports = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreSports'));
const InterestArts = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestArts'));
const ExploreArts = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreArts'));
const InterestCultural = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestCultural'));
const ExploreCultural = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreCultural'));
const InterestEnviroment = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestEnviroment'));
const ExploreEnviroment = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreEnviroment'));
const InterestScience = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestScience'));
const ExploreScience = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreScience'));
const InterestVolunteer = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestVolunteer'));
const ExploreVolunteer = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreVolunteer'));
const InterestLeadership = React.lazy(() => import('./pages/scholamatch/containers/Interest/InterestLeadership'));
const ExploreLeadership = React.lazy(() => import('./pages/scholamatch/containers/Interest/ExploreLeadership'));
const Distance = React.lazy(() => import('./pages/scholamatch/containers/Distance'));
const Transport = React.lazy(() => import('./pages/scholamatch/containers/Transport'));
const SendMatches = React.lazy(() => import('./pages/scholamatch/containers/SendMatches'));
const SendMatchesEmail = React.lazy(() => import('./pages/scholamatch/containers/SendMatchesEmail'));
const LoadingResults = React.lazy(() => import('./pages/scholamatch/containers/LoadingResults'));
const ResponseConnectRequestPage = React.lazy(() => import('components/pages/connections/ResponseConnectRequestPage'));
const SchoolProfilePageV3 = React.lazy(() => import('components/pages/school-profile-page/SchoolProfilePage'));
/* const ParentPortalBoardName = React.lazy(() => import('components/pages/parent-portal/ParentPortalBoardName'));
const ParentPortalBoardNameV2 = React.lazy(() => import('components/pages/parent-portal/ParentPortalBoardNameV2'));
const ParentPortalBoardMap = React.lazy(() => import('components/pages/parent-portal/ParentPortalBoardMap'));
const ParentPortalCompare = React.lazy(() => import('components/pages/parent-portal/ParentPortalCompare')); */
const ParentPortalStudents = React.lazy(() => import('components/pages/parent-portal/ParentPortalStudents'));
const Tours = React.lazy(() => import('./pages/parent-portal/Tours/container/Tours'));
const Favorites = React.lazy(() => import('./pages/parent-portal/Favorites/container/Favorites'));
const FavoriteBoard = React.lazy(() =>
  import('./pages/parent-portal/Favorites/container/favorite-board/favorite-board')
);
const TrackSection = React.lazy(() => import('./pages/parent-portal/track/containers/track-section'));
const ParentPortalAccountSettings = React.lazy(() =>
  import('components/pages/parent-portal/ParentPortalAccountSettings')
);
const ParentPortalSavedSearches = React.lazy(() => import('components/pages/parent-portal/ParentPortalSavedSearches'));
const ParentPortalApplications = React.lazy(() => import('components/pages/parent-portal/ParentPortalApplications'));
/* const BoardPage = React.lazy(() => import('components/pages/parent-portal/BoardPage.js')); */
const BoardPageV2 = React.lazy(() => import('components/pages/parent-portal/BoardPageV2.js'));
const ShortIdHandlerPage = React.lazy(() => import('components/pages/ShortIdHandlerPage'));
const PartnerPortal = React.lazy(() => import('components/pages/partner-portal/PartnerPortal'));
const SingleBlog = React.lazy(() => import('components/pages/blog/SingleBlog'));
const BlogHome = React.lazy(() => import('components/pages/blog/BlogHome'));
const BlogCategory = React.lazy(() => import('components/pages/blog/BlogCategory'));
const BlogSearchResult = React.lazy(() => import('components/pages/blog/BlogSearchResult'));
const NotFound404 = React.lazy(() => import('components/pages/error/NotFound404'));
const Privacy = React.lazy(() => import('components/pages/privacy/privacy'));
const ScholaRideLanding = React.lazy(() => import('components/pages/schola-ride/ScholaRideLanding'));
const ParentReferral = React.lazy(() => import('components/pages/parent-referral/ReferralLanding'));
const ParentPortalReferrals = React.lazy(() =>
  import('components/pages/parent-portal-referrals/ParentPortalReferrals')
);
const ReferralConfirmation = React.lazy(() => import('components/pages/parent-referral/ReferralConfirmation'));
const Launch = React.lazy(() => import('components/pages/launch/Launch'));
const Claim = React.lazy(() => import('components/pages/launch/Claim'));
// results v2
const SchoolsPageResults = React.lazy(() => import('components/pages/schools-results/SchoolsResults'));

const WebflowPage = React.lazy(() => import('components/pages/webflow/WebflowPage'));
const StartSchoolSearchG = React.lazy(() => import('components/pages/start-school-search-g/start-school-search-g'));
const ScholatMatchJF = React.lazy(() => import('components/pages/schola-match-jf/schola-match-jf'));
// static pages
const FormSortBaltimore = React.lazy(() => import('./pages/static/baltimorecollegiate/form-sort'));
const ThankYouPageBaltimore = React.lazy(() => import('./pages/static/baltimorecollegiate/thankyou-page'));
const OrangeCountryAcademyLanding = React.lazy(() => import('./pages/static/orange-country-academy/landing-page'));

const updatePage = () => {};

const requireAuthToken = (nextState, replace) => {
  if (!store.getState().auth.token) {
    replace({
      pathname: `/login`,
      state: { pathname: nextState.location.pathname, query: nextState.location.query },
    });
  }
};

const isUserAuthenticated = (nextState, replace) => {
  const token = store.getState().auth.token;

  if (token) {
    replace({
      pathname: `/parent-portal`,
    });
  }
};

const requireSuperadmin = (nextState, replace) => {
  const token = store.getState().auth.token;
  if (!token) {
    replace({ pathname: '/login' });
  } else if (!isSuperadmin(token)) {
    replace({ pathname: '/' });
  }
};

const onChangeRequireAuthToken = (nextState, replace, callback) => {
  if (!store.getState().auth.token) {
    callback({
      pathname: `/login`,
      state: { pathname: replace.location.pathname, query: replace.location.query },
    });
  }
};

const onEnterRequireAuthToken = (nextState, replace) => {
  if (
    nextState.location.query.endorsefeaturevisible === 'true' ||
    nextState.location.query.leadformvisible === 'true' ||
    nextState.location.query.reviewvisible === 'true' ||
    nextState.location.query.visibleModal
  ) {
    if (!store.getState().auth.token) {
      replace({
        pathname: `/login`,
        state: { pathname: nextState.location.pathname, query: nextState.location.query },
      });
    }
  }
};

const redirectValidAuth = (nextState, replace) => {
  if (store.getState().auth.token) {
    replace({ pathname: '/' });
  }
};

class Routes extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <Modal isVisible className="modal-loading">
            <div className="img-loading-search" style={{ display: 'block' }}>
              <img src="https://static.schola.com/LoadingAnimation200Opt.gif" alt="schola loading" loading="lazy" />
            </div>
          </Modal>
        }>
        <ScholaMatchProvider>
          <Router history={browserHistory} render={applyRouterMiddleware(useScroll())} onUpdate={updatePage}>
            <Route path="/">
              <IndexRoute component={WebflowPage} />
            </Route>
            <Route path="/redirect" component={RedirectPage} />
            <Route path="/login" component={LoginPage} onEnter={redirectValidAuth} />
            <Route path="/signup" component={SignupPage} onEnter={redirectValidAuth} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/scholaMatch-old" component={ScholaMatchV3} />
            <Route path="/start-school-search" component={StartSchoolSearchG} />
            <Route path="/schola-match-jf" component={ScholatMatchJF} />
            <Route path="/schola-match-fo" component={scholaMatchFillout} />
            <Route path="/schola-match-v6" component={scholaMatchFilloutv6} />
            <Route path="/personality-match" component={PersonalityMatch} />
            <Route path="/:schoolId/baltimorecollegiate/application" component={FormSortBaltimore} />
            <Route path="/:schoolId/baltimorecollegiate/application/documents" component={FormSortBaltimore} />
            <Route path="/:schoolId/baltimorecollegiate/application/thank-you" component={ThankYouPageBaltimore} />
            <Route path="/ocasa" component={OrangeCountryAcademyLanding} />

            <Route path="/launch" component={Launch} />
            <Route path="/claim" component={Claim} onEnter={requireSuperadmin} />
            <Redirect from="/scholaMatch" to="/scholaMatch/basic-info" />
            <Route path="/scholaMatchv5" component={ScholaMatchV5} />
            <Route path="/scholaMatch" component={ScholaMatchV4} />
            <Route path="/scholaMatch" component={ScholaMatchLayout}>
              <Route path="basic-info" component={InfoSection} />
              <Route path="school-type">
                <IndexRoute component={SchoolType} />
                <Route path="learning-environment" component={LearningEnvironment} />
                <Route path="specific-programs" component={SpecificPrograms} />
                <Route path="single-gender-school" component={SingleSexSchool} />
                <Route path="with-religion" component={WithReligious} />
                <Route path="religious-affiliation" component={ReligiousAffiliation} />
              </Route>
              <Route path="interest">
                <IndexRoute component={HowDoYouFeelExtracurricular} />
                <Route path="select-activities" component={SelectActivities} />
                <Route path="interest-sports" component={InterestSports} />
                <Route path="explore-sports" component={ExploreSports} />
                <Route path="interest-arts" component={InterestArts} />
                <Route path="explore-arts" component={ExploreArts} />
                <Route path="interest-science" component={InterestScience} />
                <Route path="explore-science" component={ExploreScience} />
                <Route path="interest-enviroment" component={InterestEnviroment} />
                <Route path="explore-enviroment" component={ExploreEnviroment} />
                <Route path="interest-cultural" component={InterestCultural} />
                <Route path="explore-cultural" component={ExploreCultural} />
                <Route path="interest-volunteer" component={InterestVolunteer} />
                <Route path="explore-volunteer" component={ExploreVolunteer} />
                <Route path="interest-leadership" component={InterestLeadership} />
                <Route path="explore-leadership" component={ExploreLeadership} />
                <Route path="want-specialities" component={WantSpecialities} />
                <Route path="select-specialization" component={ChooseSpecialities} />
              </Route>
              <Route path="distance">
                <IndexRoute component={Distance} />
                <Route path="transport" component={Transport} />
              </Route>
              <Route path="support-services">
                <IndexRoute component={SupportServices} />
                <Route path="child-need-english-learning-support" component={NeedEnglishLearningSupport} />
                <Route
                  path="child-need-school-special-education-services"
                  component={ChildNeedSpecialEducationServices}
                />
                <Route path="child-have-special-education-needs" component={ChildHaveSpecialEducationNeeds} />
              </Route>
              <Route path="must-have">
                <Route path="are-you-interested-specific-school" component={SpecificSchool} />
                <Route path="are-you-interested-specific-school-select" component={SpecificSchoolSelect} />
                <Route path="select-characteristic-ideal-school" component={SelectCharacteristicIdealSchool} />
                <Route
                  path="which-characteristic-be-nice-your-school-have"
                  component={WhichCharacteristicBeNiceSchoolHave}
                />
              </Route>
              <Route path="last-step">
                <Route path="want-matches-in-inbox" component={SendMatches} />
                <Route path="email" component={SendMatchesEmail} />
              </Route>
              <Route path="loading-results" component={LoadingResults} />
            </Route>

            <Route path="/scholaMatch-spot" component={ScholaMatchSpot} />
            <Route path="/scholaMatch-spot" component={ScholaMatchLayout}>
              <Route path="basic-info" component={InfoSection} />
              <Route path="school-type">
                <IndexRoute component={SchoolType} />
                <Route path="learning-environment" component={LearningEnvironment} />
                <Route path="specific-programs" component={SpecificPrograms} />
                <Route path="single-gender-school" component={SingleSexSchool} />
                <Route path="with-religion" component={WithReligious} />
                <Route path="religious-affiliation" component={ReligiousAffiliation} />
              </Route>
              <Route path="interest">
                <IndexRoute component={HowDoYouFeelExtracurricular} />
                <Route path="select-activities" component={SelectActivities} />
                <Route path="interest-sports" component={InterestSports} />
                <Route path="explore-sports" component={ExploreSports} />
                <Route path="interest-arts" component={InterestArts} />
                <Route path="explore-arts" component={ExploreArts} />
                <Route path="interest-science" component={InterestScience} />
                <Route path="explore-science" component={ExploreScience} />
                <Route path="interest-enviroment" component={InterestEnviroment} />
                <Route path="explore-enviroment" component={ExploreEnviroment} />
                <Route path="interest-cultural" component={InterestCultural} />
                <Route path="explore-cultural" component={ExploreCultural} />
                <Route path="interest-volunteer" component={InterestVolunteer} />
                <Route path="explore-volunteer" component={ExploreVolunteer} />
                <Route path="interest-leadership" component={InterestLeadership} />
                <Route path="explore-leadership" component={ExploreLeadership} />
                <Route path="want-specialities" component={WantSpecialities} />
                <Route path="select-specialization" component={ChooseSpecialities} />
              </Route>
              <Route path="distance">
                <IndexRoute component={Distance} />
                <Route path="transport" component={Transport} />
              </Route>
              <Route path="support-services">
                <IndexRoute component={SupportServices} />
                <Route path="child-need-english-learning-support" component={NeedEnglishLearningSupport} />
                <Route
                  path="child-need-school-special-education-services"
                  component={ChildNeedSpecialEducationServices}
                />
                <Route path="child-have-special-education-needs" component={ChildHaveSpecialEducationNeeds} />
              </Route>
              <Route path="must-have">
                <Route path="are-you-interested-specific-school" component={SpecificSchool} />
                <Route path="are-you-interested-specific-school-select" component={SpecificSchoolSelect} />
                <Route path="select-characteristic-ideal-school" component={SelectCharacteristicIdealSchool} />
                <Route
                  path="which-characteristic-be-nice-your-school-have"
                  component={WhichCharacteristicBeNiceSchoolHave}
                />
              </Route>
              <Route path="last-step">
                <Route path="want-matches-in-inbox" component={SendMatches} />
                <Route path="email" component={SendMatchesEmail} />
              </Route>
              <Route path="loading-results" component={LoadingResults} />
            </Route>
            <Route path="/scholaMatch-result-spot" component={ScholaMatchResultSpot} />
            <Route path="/" component={MainLayoutMarketing}>
              <Route path="about-us" component={AboutUs} />
            </Route>
            <Route path="/" component={MainLayout}>
              <Route path="old" component={LandingPagev2} />
              <Route path="/blog" component={BlogHome} />
              <Route path="/blog/category/:slug" component={BlogCategory} />
              <Route path="/blog/results/:search" component={BlogSearchResult} />
              <Route path="/blog/:slug" component={SingleBlog} />
              <Route path="/ride" component={ScholaRideLanding} />
              <Route path="/referral/:code" component={ParentReferral} />
              <Route
                path="/referral-confirmation"
                component={ReferralConfirmation}
                // onChange={onChangeRequireAuthToken}
                // onEnter={onEnterRequireAuthToken}
              />
              <Route path="contact" component={ContactPage} />
              <Route path="privacy" component={Privacy} />
              <Route path="schools-results" component={SchoolsPageResults} />
              <Route path="schools-results-v2" component={ScholaResultsv2} />
              <Route path="Personality-overview/:personality" component={PersonalityOverview} />
              <Route path="personality-match/:personality" component={ScholaResultsv2} />
              <Route path="schola-match" component={ScholaMatchResults} />
              <Route path="schola-match-b" component={ScholaMatchResults} />
            </Route>
            <Route path="/s/:shortId" component={ShortIdHandlerPage} />
            {/* Parent portal - 20191210 */}
            <Route path="/partner-portal" component={MainLayout} onEnter={requireAuthToken}>
              <IndexRoute component={PartnerPortal} />
            </Route>
            <Route path="/parent-portal-v2" component={MainLayout} onEnter={requireAuthToken}>
              <IndexRoute component={BoardPageV2} />
              <Route path="boards" component={BoardPageV2} />
            </Route>
            <Route path="/parent-portal" component={MainLayout} onEnter={requireAuthToken}>
              <IndexRoute component={TrackSection} />
              <Route path="favorites" component={Favorites} />
              <Route path="favorites/:board_id" component={FavoriteBoard} />
              <Route path="students" component={ParentPortalStudents} />
              <Route path="tours" component={Tours} />
              <Route path="account-settings" component={ParentPortalAccountSettings} />
              <Route path="saved-searches" component={ParentPortalSavedSearches} />
              <Route path="applications" component={ParentPortalApplications} />
              <Route path="referrals" component={ParentPortalReferrals} />
              <Route path="track" component={TrackSection} />
            </Route>
            <Route path="/" component={MainLayout}>
              <Route path="connect-response" component={ResponseConnectRequestPage} />
            </Route>
            <Route path="/" component={MainLayout}>
              <Route path="schools" component={SchoolsResults} />
              <Route
                path="schools/old/:state/:city/:schoolType/:id"
                onChange={onChangeRequireAuthToken}
                onEnter={onEnterRequireAuthToken}
                components={{ children: SchoolProfileLayout }}>
                <IndexRoute component={SchoolProfilePageV3} />
              </Route>
              <Route path="schools/:state/:city/:schoolType/:id" component={SchoolProfile} />
            </Route>
            <Route path="schools/:state/:city/:schoolType/:id/application(/:token)" components={WhitelabelLayout}>
              <IndexRoute component={SchoolApplicationPage} />
            </Route>
            <Route path="/wp">
              <IndexRoute component={WebflowPage} />
              <Route path="/wp/:pagename" component={WebflowPage} />
            </Route>
            <Route path="/">
              <IndexRoute component={WebflowPage} />
              <Route path=":state/:pagename" component={WebflowPage} />
            </Route>
            <Route path="*" component={NotFound404} />
          </Router>
        </ScholaMatchProvider>
      </Suspense>
    );
  }
}

export default Routes;
